.product_status {
  padding-left: 20px;
  position: relative;
  span {
    font-size: 11px;
    line-height: 20px;
    text-transform: uppercase;
  }
  &::before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    top: calc(50% - 5px);
    left: 0;
    background: #000;
    border: 2px solid rgba($color: #fff, $alpha: 0.8);
  }
  &__big {
    &::before {
      width: 14px;
      height: 14px;
      border-radius: 7px;
      top: calc(50% - 7px);
    }
  }
  &.success {
    span {
      color: #0abc88;
    }
    &::before {
      background: #0abb87;
    }
  }
  &.danger {
    span {
      color: #fd397a;
    }
    &::before {
      background: #fd397a;
    }
  }
  &.warning {
    span {
      color: #ffb822;
    }
    &::before {
      background: #ffb822;
    }
  }
}