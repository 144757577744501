.tree__line--horizontal {
  width: 27px;
}
.tree__line--vertical {
  bottom: 7px;
}

.tree__item {
  cursor: pointer;
  & > .table .button__tree {
    position: relative;
    &::before {
      content: '';
      width: 30px;
      height: 30px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
