$spaceamounts: (
  5,
  10,
  15,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  75,
  100
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

.flex {
  display: flex;
  &.jcsb {
    justify-content: space-between;
  }
  &.jcfe {
    justify-content: flex-end;
  }
  &.jcc {
    justify-content: center;
  }
  &.aic {
    align-items: center;
  }
  &.aife {
    align-items: flex-end;
  }
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
@for $value from 10 through 100 {
  .flex-#{$value} {
    flex-basis: #{$value + '%'};
    max-width: #{$value + '%'};
  }
}

@for $value from 10 through 500 {
  @if $value % 10 == 0 {
    .flex-px-#{$value} {
      flex-basis: #{$value + 'px'};
      max-width: #{$value + 'px'};
    }
  }
}
