.dashboard {
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 13px rgba(82, 63, 105, 0.047);
  margin-bottom: 4px;
  &--small {
    flex-basis: 49.2%;
  }
  &__button-section {
    padding: 10px 20px;
  }
  &__list {
    &-item {
      display: flex;
      align-items: center;
      padding: 10px 20px;
      position: relative;
      .country {
        margin-right: auto;
        &-label--hidden {
          visibility: hidden;
        }
      }
      .no-margin {
        margin: 0;
      }
      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: $separator;
      }
    }
  }
  &__form {
    &__submit {
      padding: 10px 20px;
    }
    &__footer {
      border-top: 1px solid #ebedf2;
      display: flex;
      justify-content: flex-end;
      padding: 10px 20px;
    }
  }
  &__channel-label {
    padding: 30px;
    padding-top: 0;
    .channel-label-text {
      margin-bottom: 11px;
    }
  }
  &__header {
    display: flex;
    padding: 15px 20px;
    min-height: 61px;
    align-items: center;
    border-bottom: 1px solid $separator;
  }
  &__title {
    margin-right: auto;
    margin-left: 10px;
  }
  &__searchfield {
    padding: 18px 30px;
    display: flex;
    .search-box {
      flex: 1;
      padding: 10px;
      border: 1px solid #e2e5ec;
      border-radius: 4px;
    }
  }
}
.main-dashboard {
  min-height: calc(100vh - 40px);
  background-color: $dashboard-background;
  &__container {
    padding-top: 5px;
  }
}

.selectbox {
  border: none;
  cursor: pointer;
  border-radius: 4px;
  background: #f0f3ff 0% 0% no-repeat padding-box;
  padding: 4px 10px;
  padding-left: 4px;
  text-align: left;
  font-family: 'Poppins';
  font-size: 13px;
  font-weight: 500;
  color: #93a2dd;
}

.dashboard-wrapper-flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  &.wrap {
    flex-flow: wrap;
  }
  &.jc-fs {
    justify-content: flex-start;
  }
}

.column-1 {
  flex-basis: calc(33.3333% - 2px);
  &--container {
    display: flex;
    flex-direction: column;
  }
}

.column-2 {
  flex-basis: calc(66.666% - 2px);
}

.column-3 {
  flex-basis: 100%;
}

.column-half {
  flex-basis: calc(50% - 2px);
}
