.color-picker {
  .inp-group {
    position: relative;
    .picker-container {
      position: absolute;
      z-index: 12;
      top: 100%;
    }
    .btn-pick-color {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 35px;
      border-radius: 4px 0px 0px 4px;
      border: 1px solid #e2e5ec;
      background-color: #f7f8fa;
      display: flex;
      align-items: center;
      justify-content: center;
      .color-box {
        width: 18px;
        height: 18px;
        border: 1px solid #e2e5ec;
        border-radius: 2px;
      }
    }
    input {
      padding-left: 42px;
    }
  }
}
