@import "~react-redux-toastr/lib/css/react-redux-toastr.min.css";
@import "~react-dropzone-uploader/dist/styles.css";
@import "reset.scss";
@import "colors.scss";
@import "helpers.scss";
@import "mixins.scss";
@import "typography.scss";
@import "expano-components.scss";
@import "partials/dashboard.scss";
@import "table.scss";
@import "partials/page-loader.scss";
@import "partials/tree.scss";
@import "partials/top-line-actions.scss";
@import "partials/main-table.scss";
@import "partials/json-editor.scss";
@import "partials/product-status.scss";
@import "partials/panel-loader.scss";

@import "partials/color-picker.scss";

.sidebar.collapsed + .main-container {
  .main-dashboard__top-line {
    left: 70px;
  }
}

@each $abbr, $name in ("t": "top", "r": "right", "b": "bottom", "l": "left") {
  @for $i from 1 through 20 {
    .m#{$abbr}-#{$i} {
      margin-#{$name}: 1px * $i;
    }
  }
  @for $i from 1 through 20 {
    .p#{$abbr}-#{$i} {
      padding-#{$name}: 1px * $i;
    }
  }
}

@for $i from 1 through 20 {
  .m-#{$i} {
    margin: 1px * $i;
  }
}

@for $i from 1 through 20 {
  .p-#{$i} {
    padding: 1px * $i;
  }
}

[class*="indicatorContainer"] {
  padding: 0 10px !important;
}
.nav-item-group {
  &__trigger {
    [fill] {
      fill: #494b74;
    }
  }
  &.active {
    .nav-item-group__trigger {
      [fill] {
        fill: #5d78ff;
      }
    }
  }
}

.dzu-dropzone {
  &::-webkit-scrollbar {
    display: none;
  }
}

.loader{
  text-align: center;
}