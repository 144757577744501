.jsoneditor {
  & > .jsoneditor-menu {
    display: none;
  }
  .jsoneditor-navigation-bar {
    display: none;
  }
  .jsoneditor-outer {
    min-height: 300px;
  }
}
